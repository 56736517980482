<template>
  <!--begin::Footer-->
  <div class="public-footer">
    <div class="d-flex justify-content-center">
      <span class="text-muted fw-bold me-1"
        >&copy; Copyright 2022 Snappy Popcorn Co., ALL RIGHTS RESERVED.</span
      >
    </div>
  </div>
  <!--end::Footer-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PublicFooter",
  setup() {
    return {};
  },
});
</script>
