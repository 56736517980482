
import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import PublicHeader from "@/layout/header/PublicHeader.vue";
import PublicFooter from "@/layout/footer/PublicFooter.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTLoader from "@/components/Loader.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { ScrollComponent } from "@/assets/ts/components/index";

import { loaderEnabled, loaderLogo } from "@/core/helpers/config";

export default defineComponent({
  name: "Layout",
  components: {
    PublicHeader,
    PublicFooter,
    KTScrollTop,
    KTLoader,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      ScrollComponent.bootstrap();
      ScrollComponent.updateAll();

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    return {
      loaderEnabled,
      loaderLogo,
      pageTitle,
      breadcrumbs,
    };
  },
});
