<template>
  <!--begin::Header-->
  <div class="d-flex flex-column">
    <div class="public-header-upper">
      <div class="center-container">
        <img
          alt="Snappy Popcorn"
          src="/media/logos/Primary-Snappy-Logo.svg"
          width="208"
        />
      </div>
    </div>
    <div class="public-header-lower snappy-primary-blue-bkgrd">
      <div class="center-container"></div>
    </div>
  </div>
  <!--end::Header-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PublicHeader",
  props: {},
  components: {},
  setup() {
    return {};
  },
});
</script>
